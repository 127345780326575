'use client';

import { Center } from '@chakra-ui/react';

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Center minHeight="100vh" backgroundColor="blue.50">
      {children}
    </Center>
  );
}
